'use es6';

import { handleActions } from 'flux-actions';
import { List } from 'immutable';
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
import LegalConsentOptions from 'MeetingsBase/models/LegalConsentOptions';
import LegalCommunicationConsent from 'MeetingsLib/models/LegalCommunicationConsent';
import { CHECKBOX_KEYS } from 'MeetingsBase/constants/legalConsent';
const initialState = List();
export default handleActions({
  [ActionTypes.PUBLIC_AVAILABILITY_INFO_FETCH_SUCCEEDED](state, action) {
    if (state.size === 0) {
      const legalConsentOptions = LegalConsentOptions.from(action.payload.bookInfo.customParams.legalConsentOptions);
      const subscriptions = legalConsentOptions.getSubscriptions();
      return subscriptions.map(subscription => new LegalCommunicationConsent({
        [CHECKBOX_KEYS.COMMUNICATION_TYPE_ID]: subscription.communicationTypeId,
        consented: false,
        required: subscription.required
      }));
    }
    return state;
  },
  [ActionTypes.SET_GIVEN_CONSENT](state, action) {
    const indexToUpdate = state.findIndex(checkbox => checkbox[CHECKBOX_KEYS.COMMUNICATION_TYPE_ID] === action[CHECKBOX_KEYS.COMMUNICATION_TYPE_ID]);
    return state.setIn([indexToUpdate, 'consented'], action.consented);
  },
  [ActionTypes.RESET_CONSENT_RESPONSES](state) {
    return state.map(checkbox => checkbox.set('consented', false));
  }
}, initialState);