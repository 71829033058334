import { createContainer } from 'usage-tracker-multi';
import events from 'MeetingsLib/events.yaml';
import { MEETINGS_APP_NAME } from 'MeetingsBase/constants/strings';
import { createBrowserHistory } from 'history';
const DEFAULT_LOCALE = 'en';
let tracker = null;
let screen = 'public';
const DEFAULT_CONTAINER_OPTS = {
  // We use the `history` package as it allows us to integrate to 3rd party Application
  // history states. This is mostly useful for Anonymous Tracker and to keep states in sync
  history: createBrowserHistory()
};
export function initializeMeetingsLibTracker(email, portalId, embedded, locale) {
  screen = embedded ? 'embedded' : 'public';
  const {
    createTracker
  } = createContainer({
    history: DEFAULT_CONTAINER_OPTS.history
  });
  tracker = createTracker({
    events,
    trackerType: 'anonymous',
    properties: {
      namespace: MEETINGS_APP_NAME,
      email,
      hubId: portalId,
      lang: locale || window.I18n ? window.I18n.locale : DEFAULT_LOCALE,
      screen
    }
  });
  return tracker;
}
export function track(eventKey, eventProperties = {}) {
  if (tracker) {
    tracker.track(eventKey, Object.assign({
      screen
    }, eventProperties));
  }
}