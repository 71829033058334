'use es6';

import { handleActions } from 'flux-actions';
import { Map as ImmutableMap } from 'immutable';
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
const initialState = ImmutableMap();
export default handleActions({
  [ActionTypes.MEETING_CREATE_FAILED](requestStatus, action) {
    return requestStatus.set('meetingCreate', action.payload.error);
  },
  [ActionTypes.MEETING_RESCHEDULE_FAILED](requestStatus, action) {
    return requestStatus.set('meetingReschedule', action.payload.error);
  },
  [ActionTypes.PUBLIC_AVAILABILITY_INFO_FETCH_FAILED](requestStatus, action) {
    return requestStatus.set('publicAvailabilityInfoFetch', action.payload.error);
  },
  [ActionTypes.MEETING_CANCEL_FAILED](requestStatus, action) {
    return requestStatus.set('meetingCancel', action.payload.error);
  }
}, initialState);