import I18n from 'I18n';
import { List, Record } from 'immutable';
import { FIFTEEN_MINUTE_MILLIS, ZERO_DURATION } from 'MeetingsBase/constants/dates';
import { FORM_FIELDS_WITH_OPTIONS, START_TIME_INTERVALS } from 'MeetingsBase/constants/enums';
import ActivityType from 'MeetingsBase/models/ActivityType';
import Availability from 'MeetingsBase/models/Availability';
import DisplayInfo from 'MeetingsBase/models/DisplayInfo';
import FormField from 'MeetingsBase/models/FormField';
import GuestSettings from 'MeetingsBase/models/GuestSettings';
import LegalConsentOptions from 'MeetingsBase/models/LegalConsentOptions';
import Reminder from 'MeetingsBase/models/Reminder';
import RollingDateRange, { MIN_DATE_VALUE } from 'MeetingsBase/models/RollingDateRange';
import WelcomeScreenInfo from 'MeetingsBase/models/WelcomeScreenInfo';
import { getChunkListFromRecord } from 'MeetingsBase/utils/availability';
export const defaults = {
  activityType: null,
  availability: new Availability(),
  availabilityTimeList: List(),
  bookingChangesEnabled: false,
  includeWorkingHoursInAvailability: false,
  confirmationEmailEnabled: true,
  customAvailabilityEndDate: null,
  customAvailabilityStartDate: null,
  description: null,
  displayInfo: new DisplayInfo(),
  durations: List([ZERO_DURATION]),
  formFields: List(),
  guestSettings: new GuestSettings(),
  includeDescriptionInReminder: false,
  language: null,
  legalConsentEnabled: false,
  legalConsentOptions: null,
  locale: null,
  location: null,
  meetingBufferTime: FIFTEEN_MINUTE_MILLIS,
  minimumNoticeTime: FIFTEEN_MINUTE_MILLIS,
  ownerPrioritized: false,
  paymentLinkInfo: null,
  pushBackTime: FIFTEEN_MINUTE_MILLIS,
  recaptchaEnabled: false,
  redirectUrl: null,
  reminders: List(),
  rollingDateRange: new RollingDateRange({
    rollingDateValue: MIN_DATE_VALUE,
    offlineDays: [],
    rollingDateUnit: 'WEEKS'
  }),
  sendReminder: false,
  shouldAutoSubmit: false,
  shouldPrefillFormFields: true,
  startTimeIncrementMinutes: START_TIME_INTERVALS.FIFTEEN,
  subject: null,
  timezone: 'Europe/Dublin',
  videoConferenceExtensionAccountId: null,
  videoConferenceExtensionAppId: null,
  weeksToAdvertise: 2,
  welcomeScreenInfo: new WelcomeScreenInfo()
};
const getVisibleFormOptions = formFields => {
  return formFields.map(field => {
    // @ts-expect-error field.type is a string but FORM_FIELDS_WITH_OPTIONS only allow checking against specific values
    if (FORM_FIELDS_WITH_OPTIONS.indexOf(field.type) > -1) {
      const visibleOptions = field.options.filter(option => {
        return !option.hidden;
      });
      field.options = visibleOptions;
    }
    return field;
  });
};
export default class LinkCustomParams extends Record(defaults) {
  static from(json) {
    if (json.timezone) {
      json.timezone = I18n.moment.tz.names().includes(json.timezone) ? json.timezone : 'Europe/Dublin';
    }
    const activityType = json.activityType ? ActivityType.from(json.activityType) : null;
    const legalConsentOptions = json.legalConsentOptions !== null ? LegalConsentOptions.from(json.legalConsentOptions) : null;
    const guestSettings = json.guestSettings ? GuestSettings.from(json.guestSettings) : new GuestSettings();
    const availability = Availability.from(json.availability);
    return new LinkCustomParams(Object.assign({}, json, {
      activityType,
      availability,
      availabilityTimeList: getChunkListFromRecord(availability),
      displayInfo: DisplayInfo.from(json.displayInfo),
      welcomeScreenInfo: WelcomeScreenInfo.from(json.welcomeScreenInfo),
      durations: List(json.durations),
      formFields: json.formFields ? List(getVisibleFormOptions(json.formFields).map(field => FormField.from(field))) : List(),
      guestSettings,
      legalConsentOptions,
      ownerPrioritized: json.ownerPrioritized || false,
      minimumNoticeTime: json.pushBackTime,
      reminders: json.reminders ? List(json.reminders.map(reminder => new Reminder(reminder))) : List(),
      videoConferenceExtensionAppId: json.videoConferenceExtensionAppId,
      videoConferenceExtensionAccountId: json.videoConferenceExtensionAccountId,
      weeksToAdvertise: json.weeksToAdvertise,
      startTimeIncrementMinutes: json.startTimeIncrementMinutes,
      confirmationEmailEnabled: json.confirmationEmailEnabled,
      pushBackTime: json.pushBackTime,
      paymentLinkInfo: json.paymentLinkInfo,
      description: json.description,
      rollingDateRange: json.rollingDateRange ? RollingDateRange.from(json.rollingDateRange) : new RollingDateRange(),
      shouldAutoSubmit: !guestSettings.canAddGuests && json.shouldAutoSubmit
    }));
  }
  convertToJSON() {
    return {
      activityType: this.activityType ? this.activityType.toJSON() : null,
      availability: this.availability.convertToJSON(),
      bookingChangesEnabled: this.bookingChangesEnabled,
      includeWorkingHoursInAvailability: this.includeWorkingHoursInAvailability,
      confirmationEmailEnabled: this.confirmationEmailEnabled,
      customAvailabilityEndDate: this.customAvailabilityEndDate,
      customAvailabilityStartDate: this.customAvailabilityStartDate,
      description: this.description,
      displayInfo: this.displayInfo.toJSON(),
      durations: this.durations.toArray(),
      formFields: this.formFields.toArray().map(formField => formField.toJSON()),
      guestSettings: this.guestSettings.toJSON(),
      includeDescriptionInReminder: this.includeDescriptionInReminder,
      language: this.language,
      legalConsentEnabled: this.legalConsentEnabled,
      legalConsentOptions: this.legalConsentOptions ? this.legalConsentOptions.convertToJSON() : null,
      locale: this.locale,
      location: this.location,
      meetingBufferTime: this.meetingBufferTime,
      ownerPrioritized: this.ownerPrioritized,
      paymentLinkInfo: this.paymentLinkInfo,
      pushBackTime: this.minimumNoticeTime,
      // Keeping this value assignment as per current implementation
      recaptchaEnabled: this.recaptchaEnabled,
      redirectUrl: this.redirectUrl,
      reminders: this.reminders.toArray().map(reminder => reminder.toJSON()),
      rollingDateRange: this.rollingDateRange.toJSON(),
      sendReminder: this.sendReminder,
      shouldAutoSubmit: this.shouldAutoSubmit,
      shouldPrefillFormFields: this.shouldPrefillFormFields,
      startTimeIncrementMinutes: this.startTimeIncrementMinutes,
      subject: this.subject,
      timezone: this.timezone,
      videoConferenceExtensionAccountId: this.videoConferenceExtensionAccountId,
      videoConferenceExtensionAppId: this.videoConferenceExtensionAppId,
      weeksToAdvertise: this.weeksToAdvertise,
      welcomeScreenInfo: this.welcomeScreenInfo.toJSON()
    };
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
}