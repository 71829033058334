'use es6';

import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
export const setPaymentSessionId = sessionId => {
  return {
    type: ActionTypes.SET_PAYMENT_SESSION_ID,
    payload: sessionId
  };
};
export const setPaymentStatus = status => {
  return {
    type: ActionTypes.SET_PAYMENT_STATUS,
    payload: status
  };
};
export const setPaymentFinalPrice = finalPrice => {
  return {
    type: ActionTypes.SET_PAYMENT_FINAL_PRICE,
    payload: finalPrice
  };
};