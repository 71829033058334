'use es6';

import UserMessage from 'MeetingsLib/models/UserMessage';
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
export function showUserMessage({
  type,
  content
}) {
  return {
    type: ActionTypes.SHOW_USER_MESSAGE,
    userMessage: new UserMessage({
      type,
      content
    })
  };
}
export function toggleTroubleshootMode(active) {
  return {
    type: ActionTypes.TOGGLE_TROUBLESHOOT_MODE,
    active
  };
}