'use es6';

import * as ActionTypes from '../actions/ActionTypes';
const initialState = null;
export default function emailFormState(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_EMAIL_FORM:
      return action.payload;
    case ActionTypes.RESET_EMAIL_FORM:
      return initialState;
    default:
      return state;
  }
}