'use es6';

import enviro from 'enviro';

// Do not remove this.
// Marketing Ops relies on this Google Tag Manager
// container to be loaded on all links that are owned by 53
const HUBSPOT_INTERNAL_PORTAL = 53;
export default function (portalId) {
  const key = 'google_analytics';
  if (portalId === HUBSPOT_INTERNAL_PORTAL && enviro.deployed(key) && enviro.getInternal(key) === 'prod') {
    const googleTagManager = function googleTagManager(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l !== 'dataLayer' ? `&l=${l}` : '';
      j.id = 'hubspot-gtm-container';
      j.async = true;
      j.src = `//www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      f.parentNode.insertBefore(j, f);
    };
    googleTagManager(window, document, 'script', 'dataLayer', 'GTM-TDNGMT');
  }
}