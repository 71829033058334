'use es6';

import { FETCH_EVENT_DETAILS_SUCCEEDED } from '../actions/ActionTypes';
const initialState = null;
export default function eventDetails(state = initialState, action) {
  switch (action.type) {
    case FETCH_EVENT_DETAILS_SUCCEEDED:
      return action.payload;
    default:
      return state;
  }
}