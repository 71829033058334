import { Record } from 'immutable';
import Event from 'MeetingsLib/models/Event';
const defaults = {
  event: new Event(),
  postResponse: null
};
export default class BookingLifecycle extends Record(defaults, 'BookingLifecycleRecord') {
  convertToJSON() {
    const {
      event,
      postResponse
    } = this;
    return {
      event: event.toJSON(),
      postResponse
    };
  }

  /**
   * Use toJSON
   * @deprecated
   */
  toJS() {
    return super.toJS();
  }

  /**
   * Use convertToJSON
   * @deprecated
   */
  toJSON() {
    return super.toJSON();
  }
}