import Raven from 'raven-js';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Meet... Remove this comment to see the full error message
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Meet... Remove this comment to see the full error message
import { getMeetingSourceFromLocation } from 'MeetingsLib/utils/utils';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Meet... Remove this comment to see the full error message
import { track } from 'MeetingsLib/tracking/UsageTracker';
export default (({
  getState
}) => next => action => {
  next(action);
  const state = getState();
  const meetingSource = getMeetingSourceFromLocation();
  const trackingParams = state.trackingParams ? state.trackingParams.compact() : {};
  switch (action.type) {
    case ActionTypes.LOG_MEETING_CREATE:
      {
        track('meetingsLibUsage', {
          action: 'booked meeting',
          referrer: trackingParams['referrer'] === 'FORMS' ? 'forms' : 'none'
        });

        // Fire google tag manager event manually on successful meeting booked
        // do not remove, marketing ops is using this event to track pqls
        try {
          // <!-- Convert Experiments conversion tracking -->
          const _conv_q = window._conv_q || [];
          _conv_q.push(['triggerConversion', '10038692']);
          const gtmMeetingBookedEvent = {
            event: 'MeetingBookedEvent',
            MeetingBookedText: 'Meeting Booked'
          };
          if (window.dataLayer && window.google_tag_manager) {
            window.dataLayer.push(gtmMeetingBookedEvent);
          }
        } catch (e) {
          Raven.captureBreadcrumb({
            message: `unable to log meeting booked via datalayer`
          });
        }
        break;
      }
    case ActionTypes.SET_EVENT_DATE:
      if (action.date) {
        track('meetingsLibSetProperty', {
          property: 'event date',
          value: action.date
        });
      }
      break;
    case ActionTypes.SET_EVENT_DATE_TIME:
      if (action.dateTime) {
        track('meetingsLibSetProperty', {
          property: 'event datetime',
          value: `${action.dateTime}`
        });
      }
      break;
    case ActionTypes.SET_EVENT_DURATION:
      track('meetingsLibSetProperty', {
        property: 'event duration',
        value: `${action.duration}`
      });
      break;
    case ActionTypes.SET_TIMEZONE:
      track('meetingsLibSetProperty', {
        property: 'timezone',
        value: action.timezone
      });
      break;
    case ActionTypes.TOGGLE_TROUBLESHOOT_MODE:
      track('meetingsLibTroubleshoot', {
        action: action.active ? 'enter' : 'exit'
      });
      break;
    case ActionTypes.MEETING_CANCEL_SUCCEEDED:
      track('meetingEventInteraction', {
        action: 'clicked cancel',
        source: meetingSource
      });
      break;
    case ActionTypes.MEETING_RESCHEDULE_SUCCEEDED:
      track('meetingEventInteraction', {
        action: 'clicked reschedule',
        source: meetingSource
      });
      break;
    case ActionTypes.PAID_MEETING_BOOKED_SUCCESSFULLY:
      track('paymentBuyer', {
        step: 'book paid meeting success'
      });
      break;
    case ActionTypes.PAID_MEETING_BOOKING_COMPLETED_PROPERTY_FORM:
      track('paymentBuyer', {
        step: 'completed property form'
      });
      break;
    case ActionTypes.PAID_MEETING_BOOKING_COMPLETED_LEGAL_CONSENT_FORM:
      track('paymentBuyer', {
        step: 'completed legal consent form'
      });
      break;
    case ActionTypes.PAID_MEETING_BOOKING_SELECTED_TIME:
      track('paymentBuyer', {
        step: 'selected time'
      });
      break;
    case ActionTypes.PAID_MEETING_BOOKING_PAYMENT_PROCESSING:
      track('paymentBuyer', {
        step: 'payment processing'
      });
      break;
    case ActionTypes.PAID_MEETING_BOOKING_PAYMENT_SUCCESSFUL:
      track('paymentBuyer', {
        step: 'payment successful'
      });
      break;
    case ActionTypes.PAID_MEETING_BOOKING_PAYMENT_FAILED:
      track('paymentBuyer', {
        step: 'payment failed'
      });
      break;
    case ActionTypes.PAID_MEETING_BOOKING_TIME_UNAVAILABLE:
      track('paymentBuyer', {
        step: 'paid meeting booked time unavailable'
      });
      break;
    case ActionTypes.PAID_MEETING_BOOKING_FAILURE:
      track('paymentBuyer', {
        step: 'book paid meeting failure'
      });
      break;
    case ActionTypes.PUBLIC_AVAILABILITY_INFO_FETCH_SUCCEEDED:
      {
        if (trackingParams['referrer'] === 'FORMS') {
          track('meetingsLibLoaded', {
            referrer: 'forms'
          });
        }
        break;
      }
    default:
  }
});