'use es6';

import Sanitize from 'sanitize';
import { FORM_FIELD_TYPES } from 'MeetingsBase/constants/enums';
const WHITE_SPACE_REGEX = /\s/g;

// prevents img resolution/script execution during sanitization, see HubSpot/MeetingsUI#2698
export const secureDocument = document.implementation.createHTMLDocument('sanitize');
const element = secureDocument.body;
export const sanitize = config => input => {
  if (!input) {
    return '';
  }
  const sanitizer = new Sanitize(config);
  try {
    secureDocument.domain = window.location.hostname.indexOf('qa') >= 0 ? 'hubspotqa.com' : 'hubspot.com';
  } catch (e) {
    // IE11 needs to have this but it throws an error in Chrome
  }
  element.innerHTML = input;

  // For specific documentation on the Sanitize.js library, config,
  // or transformers, see: https://github.com/gbirke/Sanitize.js
  const fragment = sanitizer.clean_node(element).cloneNode(true);
  const container = secureDocument.createElement('div');
  container.appendChild(fragment);
  return container.innerHTML;
};
export const config = {
  elements: ['a', 'br', 'li', 'ol', 'p', 'sub', 'sup', 'u', 'ul'],
  attributes: {
    a: ['href']
  },
  protocols: {
    a: {
      href: ['http', 'https', Sanitize.RELATIVE]
    }
  },
  remove_contents: ['script']
};
const fieldSanitizer = sanitize(config);
const fieldParser = new DOMParser();
const RESERVED_PATTERN = /&(amp|lt|gt|quot);/gm;
const RESERVED_CHARS = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"'
};
const PHONE_LINK_PATTERN = /^tel:/gm;
export const reverseReservedChars = input => {
  if (!input.match(RESERVED_PATTERN)) return input;
  let output = input;
  Object.entries(RESERVED_CHARS).forEach(entry => {
    output = output.replace(new RegExp(entry[0], 'gm'), entry[1]);
  });
  return output;
};
export const getLinkFromAnchor = anchorElement => {
  let link;
  if (anchorElement) {
    link = anchorElement.getAttribute('href');
  } else {
    link = '';
  }

  // iOS specific workaround for DOMParser turning a string of numbers into
  // a number link anchor
  if (link && link.match(PHONE_LINK_PATTERN)) {
    return link.slice(4);
  }
  return link || anchorElement.textContent;
};
export const sanitizeScriptUrl = input => {
  // eslint-disable-next-line no-script-url
  if (input.includes('javascript:')) {
    return input.replace(/(javascript:)*/gim, '');
  }
  return input;
};
export const maybeSanitizePhoneNumber = (fieldValue, fieldType) => {
  if (fieldType && fieldType === FORM_FIELD_TYPES.PHONE_NUMBER && fieldValue) {
    return fieldValue.replace(WHITE_SPACE_REGEX, '');
  }
  return fieldValue;
};
export const sanitizeFormFields = (fields, fieldsMetadata = []) => {
  const sanitizedFormFields = [];
  for (const field of fields) {
    const fieldMeta = fieldsMetadata.find(fieldMetadata => fieldMetadata.name === field.name);
    const fieldType = fieldMeta && fieldMeta.fieldType || '';
    const sanitizedFieldValue = sanitizeString(field.value, fieldType);
    sanitizedFormFields.push(Object.assign({}, field, {
      value: sanitizedFieldValue
    }));
  }
  return sanitizedFormFields;
};
export function sanitizeString(input, fieldType = '') {
  /**
   * Step 1
   * Sanitize tags that we absolutely do not want to allow through
   * This will remove any script tags and also sanitize anchor tags
   * to only contain href attributes constrained to certain protocols
   */
  let sanitizedInput = fieldSanitizer(input);

  /**
   * Step 2
   * For any anchor tags, we want to replace content text to
   * expose the underlying href so any links cannot be hidden behind
   * normal-looking text
   */
  const anchorElements = fieldParser.parseFromString(sanitizedInput, 'text/html').querySelectorAll('a');
  if (anchorElements.length > 0) {
    const anchorLinks = [];
    for (const anchorElement of anchorElements) {
      anchorLinks.push(getLinkFromAnchor(anchorElement));
    }
    sanitizedInput = anchorLinks.join(' ');
  }

  /**
   * Step 3
   * Clean up any remaining "javascript:" calls that was unfiltered
   * This is possible if the calls are in text content
   */
  sanitizedInput = sanitizeScriptUrl(sanitizedInput);

  /**
   * Step 4
   * For any valid content with special HTML characters that were turned into their entities,
   * we need to do a reverse conversion to pass backend validation
   */
  sanitizedInput = reverseReservedChars(sanitizedInput);

  /**
   * Step 5
   * Clean up whitespace from phone numbers
   */
  sanitizedInput = maybeSanitizePhoneNumber(sanitizedInput, fieldType);
  return sanitizedInput;
}