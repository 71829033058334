'use es6';

import { handleActions, combineActions } from 'flux-actions';
import BookInfo from 'MeetingsBase/models/PublicAvailabilityInfo';
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
const initialState = new BookInfo();
export default handleActions({
  [combineActions(ActionTypes.PUBLIC_AVAILABILITY_INFO_FETCH_SUCCEEDED, ActionTypes.PUBLIC_AVAILABILITY_INFO_OWNER_FETCH_SUCCEEDED)](state, action) {
    return action.payload.bookInfo;
  },
  [ActionTypes.PUBLIC_AVAILABILITY_INFO_FETCH_STARTED](state) {
    return state.setIn(['linkAvailability', 'isProcessing'], true);
  },
  [ActionTypes.UPDATE_LINK_AVAILABILITY](state, action) {
    const currentAvailability = state.get('linkAvailability');
    const updatedAvailability = currentAvailability.updateWithIncomingInstance({
      incomingLinkAvailability: action.payload.linkAvailability,
      currentMonthOffset: action.payload.currentMonthOffset
    });
    return state.set('linkAvailability', updatedAvailability);
  },
  [ActionTypes.UPDATE_USER_BUSY_TIMES](state, action) {
    const updatedState = state.updateUserBusyTimes(action.payload.allUsersBusyTimes);
    return updatedState;
  },
  [ActionTypes.BOOKING_TIME_CONFLICT](state, action) {
    return state.set('linkAvailability', action.payload.linkAvailability);
  }
}, initialState);