'use es6';

import { handleActions } from 'flux-actions';
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
const initialState = null;
export default handleActions({
  [ActionTypes.SHOW_USER_MESSAGE](state, action) {
    const {
      userMessage
    } = action;
    return userMessage;
  }
}, initialState);