'use es6';

import I18n from 'I18n';
import getTranslatedTimezoneGroups from 'timezone-utils/utils/getTranslatedTimezoneGroups';
import groupsToSelectOpts from 'timezone-utils/utils/groupsToSelectOpts';
let previousLocale;
let options;
let optionsMap;
export default function () {
  const shouldRefetch = I18n.locale !== previousLocale;
  previousLocale = I18n.locale;
  if (!options || shouldRefetch) {
    options = groupsToSelectOpts(getTranslatedTimezoneGroups());
    optionsMap = options.reduce((accI, optionGroup) => {
      return optionGroup.options.reduce((accJ, option) => {
        accJ[option.value] = option.text;
        return accJ;
      }, accI);
    }, {});
  }
  return {
    options,
    optionsMap
  };
}