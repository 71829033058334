'use es6';

import { UPDATE_RECAPTCHA_TOKEN, UPDATE_RECAPTCHA_WIDGET_ID } from '../actions/ActionTypes';
const initialState = {
  widgetId: null,
  token: null
};
export default function recaptcha(state = initialState, action) {
  switch (action.type) {
    case UPDATE_RECAPTCHA_WIDGET_ID:
      return Object.assign({}, state, {
        widgetId: action.payload
      });
    case UPDATE_RECAPTCHA_TOKEN:
      return Object.assign({}, state, {
        token: action.payload
      });
    default:
      return state;
  }
}