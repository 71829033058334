'use es6';

import { applyMiddleware, createStore as createReduxStore, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from 'MeetingsLib/redux/reducers/root';
import RavenContextMiddleware from 'MeetingsLib/redux/middleware/RavenContextMiddleware';
import TrackingMiddleware from 'MeetingsLib/redux/middleware/TrackingMiddleware';
export function createStore(initialState, httpClient) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const createStoreWithMiddleware = composeEnhancers(applyMiddleware(thunk.withExtraArgument(httpClient), TrackingMiddleware, RavenContextMiddleware))(createReduxStore);
  return createStoreWithMiddleware(reducer, initialState);
}