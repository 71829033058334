'use es6';

import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
import { canSubmitBooking, canSubmitMeetingTime } from 'MeetingsLib/selectors/AutoSubmitSelector';
import { getIsLegitimateInterest, getRecaptchaEnabled, getPaidMeetingsAvailableSelector } from 'MeetingsLib/selectors/BookInfoSelectors';
import { getPaymentSuccessfulSelector } from '../../selectors/PaymentStatusSelectors';
import { bookMeeting, rescheduleMeeting, createMeetingReset } from './BookActions';
import { invokeChallenge } from './RecaptchaActions';
export const trackPaidMeetingCompletedPropertyForm = () => {
  return {
    type: ActionTypes.PAID_MEETING_BOOKING_COMPLETED_PROPERTY_FORM
  };
};
export const trackPaidMeetingCompletedLegalConsentForm = () => {
  return {
    type: ActionTypes.PAID_MEETING_BOOKING_COMPLETED_LEGAL_CONSENT_FORM
  };
};
export const trackPaidMeetingSelectedTime = () => {
  return {
    type: ActionTypes.PAID_MEETING_BOOKING_SELECTED_TIME
  };
};
export const trackPaidMeetingPaymentSuccessful = () => {
  return {
    type: ActionTypes.PAID_MEETING_BOOKING_PAYMENT_SUCCESSFUL
  };
};
export const trackPaidMeetingPaymentProcessing = () => {
  return {
    type: ActionTypes.PAID_MEETING_BOOKING_PAYMENT_PROCESSING
  };
};
export const trackPaidMeetingPaymentFailed = () => {
  return {
    type: ActionTypes.PAID_MEETING_BOOKING_PAYMENT_FAILED
  };
};
export function setEmailForm(email) {
  return {
    type: ActionTypes.SET_EMAIL_FORM,
    payload: email
  };
}
export function resetEmailForm() {
  return {
    type: ActionTypes.RESET_EMAIL_FORM
  };
}
export function setEventDate(dateString) {
  return {
    type: ActionTypes.SET_EVENT_DATE,
    date: dateString
  };
}
export function resetEventDate() {
  return {
    type: ActionTypes.SET_EVENT_DATE,
    date: null
  };
}
export const setEventDateTime = millisDateTime => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SET_EVENT_DATE_TIME,
    dateTime: millisDateTime
  });
  const state = getState();
  const isPaidMeeting = getPaidMeetingsAvailableSelector(state);
  if (isPaidMeeting) {
    dispatch(trackPaidMeetingSelectedTime());
    // meeting will be booked by payment step via bookPaidMeeting
    return Promise.resolve();
  }
  if (canSubmitBooking(state)) {
    if (getRecaptchaEnabled(state)) {
      return Promise.resolve();
    }
    return dispatch(bookMeeting());
  }
  return Promise.resolve();
};
export const resetEventDateTime = () => {
  return {
    type: ActionTypes.SET_EVENT_DATE_TIME,
    dateTime: null
  };
};
export function setEventDuration(duration) {
  return {
    type: ActionTypes.SET_EVENT_DURATION,
    duration
  };
}
export function setGivenConsent(checkbox, checked) {
  return {
    type: ActionTypes.SET_GIVEN_CONSENT,
    communicationTypeId: checkbox.communicationTypeId,
    consented: checked
  };
}
export const submitConsentResponses = stagedLegalConsentResponses => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SET_CONSENT_RESPONSES,
    payload: stagedLegalConsentResponses
  });
  if (getIsLegitimateInterest(getState())) {
    dispatch({
      type: ActionTypes.SET_LEGITIMATE_INTEREST_CONFIRMED
    });
  }
  const state = getState();
  const isPaidMeeting = getPaidMeetingsAvailableSelector(state);
  if (isPaidMeeting) {
    dispatch(trackPaidMeetingCompletedLegalConsentForm());
    // meeting will be booked by payment step via bookPaidMeeting
    return Promise.resolve();
  }
  if (canSubmitBooking(state)) {
    if (getRecaptchaEnabled(state)) {
      dispatch(invokeChallenge());
      return Promise.resolve();
    }
    return dispatch(bookMeeting());
  }
  return Promise.resolve();
};
export const bookPaidMeeting = () => (dispatch, getState) => {
  const state = getState();
  const isPaidMeeting = getPaidMeetingsAvailableSelector(state);
  const isPaymentSuccessful = getPaymentSuccessfulSelector(state);
  if (isPaidMeeting && isPaymentSuccessful) {
    dispatch(trackPaidMeetingPaymentSuccessful());
    if (getRecaptchaEnabled(state)) {
      dispatch(invokeChallenge());
      return Promise.resolve();
    }
    return dispatch(bookMeeting());
  }
  return Promise.resolve();
};
export function resetConsentResponses() {
  return {
    type: ActionTypes.RESET_CONSENT_RESPONSES
  };
}
export function setTimezone(timezone) {
  return {
    type: ActionTypes.SET_TIMEZONE,
    timezone
  };
}
export function setFormValue(formFieldName, formFieldValue) {
  return {
    type: ActionTypes.SET_FORM_FIELD,
    payload: {
      fieldName: formFieldName,
      fieldValue: formFieldValue
    }
  };
}
export function resetFormState() {
  return {
    type: ActionTypes.RESET_FORM_STATE
  };
}
export const resetForPaidMeetingBookingFailure = () => dispatch => {
  dispatch(resetFormState());
  dispatch(resetConsentResponses());
  dispatch(resetEventDateTime());
  dispatch(resetEventDate());
  dispatch(createMeetingReset());
};
export const setFormState = stagedFormState => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SET_FORM_STATE,
    payload: stagedFormState
  });
  const state = getState();
  const isPaidMeeting = getPaidMeetingsAvailableSelector(state);
  const {
    bookingLifecycle: {
      event
    },
    isRescheduling
  } = state;
  if (isRescheduling && canSubmitMeetingTime(state)) {
    return dispatch(rescheduleMeeting(event));
  }
  if (isPaidMeeting) {
    dispatch(trackPaidMeetingCompletedPropertyForm());
    // meeting will be booked by payment step via bookPaidMeeting
    return Promise.resolve();
  }
  if (canSubmitBooking(state)) {
    if (getRecaptchaEnabled(state)) {
      dispatch(invokeChallenge());
      return Promise.resolve();
    }
    return dispatch(bookMeeting());
  }
  return Promise.resolve();
};
export const addGuestEmail = guestEmail => {
  return {
    type: ActionTypes.ADD_GUEST_EMAIL,
    payload: guestEmail
  };
};
export const removeGuestEmail = guestEmail => {
  return {
    type: ActionTypes.REMOVE_GUEST_EMAIL,
    payload: guestEmail
  };
};