'use es6';

import { handleActions, combineActions } from 'flux-actions';
import Profile from 'MeetingsBase/models/UserProfile';
import * as ActionTypes from 'MeetingsLib/redux/actions/ActionTypes';
const initialState = new Profile();
export default handleActions({
  [combineActions(ActionTypes.PUBLIC_AVAILABILITY_INFO_FETCH_SUCCEEDED, ActionTypes.MEETINGS_USER_FETCH_SUCCEEDED)](state, action) {
    const {
      meetingsUser
    } = action.payload;
    if (!meetingsUser) {
      return state;
    }
    return meetingsUser.userProfile;
  }
}, initialState);