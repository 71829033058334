'use es6';

import * as ActionTypes from '../actions/ActionTypes';
import { handleActions, combineActions } from 'flux-actions';
const initialState = null;
export default handleActions({
  [ActionTypes.SET_CONSENT_RESPONSES](state, action) {
    return action.payload;
  },
  [combineActions(ActionTypes.RESET_CONSENT_RESPONSES, ActionTypes.BOOKING_TIME_CONFLICT)]() {
    return initialState;
  }
}, initialState);